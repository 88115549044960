import volrans from "./volrans.png";

export const characters = [
    {
        name: 'Volrans',
        kin: 'vessel',
        description: 'Sentinel of Caleste, vessel of ancient power, all-seeing prince of the realm he protects.',
        quote: 'Yearning gets old after a few centuries.',
        image: volrans
    },
    {
        name: 'Sarya',
        kin: 'abyssal',
        description: 'A mage forsaken by the deities she once served.',
    },
    {
        name: 'White',
        description: 'The blade dancer smiles and offers a helping hand. But what could her true motivations be?',
    },
    {
        name: 'Nolidan',
        kin: 'kindled one',
        description: 'Born from the flames of the Underworld, this wandering duelist has made of himself a loathed acquaintance to many people along the way.',
    },
    {
        name: 'Otsugaeru',
        kin: 'dragon',
        description: 'The Moon Dragon shattered herself to preserve a portion of the world of a great calamity.'
    },
    {
        name: 'Karmidan',
        kin: 'dragon',
        description: 'The gardener on the moon.'
    },
    {
        name: 'Karmuidurj',
        kin: 'dragon',
        description: 'To her belongs all which has been touched by death.'
    }

]

export const kin = [
    {
        name: 'vessel',
        tier: 'mortal'
    },
    {
        name: 'primordial',
        tier: 'dragon'
    }
]

//helve?