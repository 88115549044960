import Card from "../../components/Card";

import placeholder from "../../assets/volrans.png";
import { characters } from "../../assets/loadingScreenLore";

export default function Garden() {
  return (
    <main>
      {/* organize archive with sections for characters, locations,
      lore etc.
      eventually add search system */}
      {characters.map((character) => {
        console.log(character)
        return (
          <Card
            key={character.name}
            size="big"
            title={character.name}
            text={character.description}
            quote={character.quote}
            image={character.image}
          />
        );
      })}

      {/* gallery with all pictures tagged with entity's name */}
    </main>
  );
}
