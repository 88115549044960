

export default function Home() {
    return(
        <main className="box-shadow">
            <p className="quote">
                Beautiful and terrifying dragons of Marabahan, gods in their land.
            </p>
            <p className="quote">
                Descenders of a higher plane and everlasting sculptors of a common plane.
            </p>
            <p className="quote">
                Graceful beings of power - rulers, guardians and gardeners.
            </p>
        </main>
    )
}