import styled from "styled-components";
import placeholder from "../../assets/volrans.png";

export const Wrapper = styled.div`
  display: flex;
  box-shadow: 0px 5px 10px 3px rgba(50, 50, 50, 0.15);
  border-radius: 8px;

  width: 100%;
  height: 75vh;
  margin-top: 8px;

  /* background: url(${placeholder}); */
  background-color: white;
  background-image: url(${(props) => props.image});
  
  background-repeat: no-repeat;
  background-position: 100% 100%;
  background-size: auto 100%;

  div {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;

    width: 50%;

    font-size: 24px;
  }

  h4 {
    position: absolute;
    top: 0;
    background-color: antiquewhite;
  }

  img {
    height: 75vh;
    border-radius: 8px;
  }
`;
