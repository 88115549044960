import React, { Component } from 'react';
import logo from './logo.svg';

import { BrowserRouter, Routes, Route } from 'react-router-dom';

import Home from './pages/Home';
import Garden from './pages/Archive';
import Navigation from './components/Navigation';

class App extends Component {
  render() {
    return (
      <BrowserRouter>
        <Navigation />
        <Routes>
          <Route path='/' element={<Home />} />
          <Route path='/garden' element={<Garden />} />
        </Routes>
      </BrowserRouter>
    );
  }
}

export default App;
