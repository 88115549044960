import { Wrapper } from "./styles";

export default function Card(props) {
   return (
    <Wrapper orientation={props.orientation} size={props.size} image={props.image}>
      <div>
        <h4>{props.title}</h4>
        <p>{props.text}</p>
        {props.quote? 
        <p className="quote">"{props.quote}"</p>
        :
        ''}
      </div>
    </Wrapper>
  );
}
