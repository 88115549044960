import styled from "styled-components";

export const StyledNav = styled.nav`
  position: fixed;
top: 0;
left: 0;

z-index: 999;

  display: flex;

  background-color: white;

  width: 100vw;
  height: 60px;
  padding: 16px;

  box-shadow: 0px 5px 10px 3px rgba(50, 50, 50, 0.15);
`;
